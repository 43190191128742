@import "../../base/styles/general/settings";
@import "variables/layout/general-rules";

$mobileNewsLetter-background: $interactions;
$mobileNewsLetter-background-type2: $interactions;
$mobileNewsLetter-form-redesign-button-background: $headings;
$mobileNewsLetter-form-redesign-button-border-color: $headings;

$prizeGameModal-borderColor: $headings;
$prizeGameModal-content-title-color: $headings;
$prizeGameModal-signUp-backgroundColor: $interactions;

$footer-aboutus-title-color: $gray-text-default;
$footer-aboutus-item-color-mobile: $gray-text-default;

@import "variables/layout/footer";
@import "../../base/styles/base";

body footer .footerContainer .questions .subContainer .row {
	img.garantee {
		float: right;
		width: auto;
		max-width: 60px;
		height: auto;
		background-color: $white;
	}
}

@include media-breakpoint-down(md) {
	body FOOTER .footerContainer .aboutus {
		background-color: $gray-light;
	}
}